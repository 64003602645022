import React from 'react'
import { Button } from 'antd'
// import PropTypes from 'prop-types'
import { injectIntl } from 'react-intl'
import { useHistory } from 'react-router-dom'
import { goBack } from 'connected-react-router'
import { useDispatch } from 'react-redux'
import { ArrowLeftOutlined } from '@ant-design/icons'

function Back({
  type = 'default',
  icon = <ArrowLeftOutlined />,
  title = 'button.back',
  onClick,
  id = 'btn-back',
  intl,
  style = { backgroundColor: '#eb9234', color: '#ffffff' },
  ...props
}) {
  const history = useHistory()
  const dispatch = useDispatch()
  if (history.length < 2 && !onClick) {
    return null
  }

  const clickHandler = () => {
    if (typeof onClick === 'function') {
      onClick()
    } else {
      dispatch(goBack())
    }
  }

  const newTitle = title === 'button.back' ? intl.formatMessage({ id: title }) : title
  return (
    <Button type={type} icon={icon} id={id} style={style} {...props} onClick={clickHandler}>
      {newTitle}
    </Button>
  )
}

// Back.propTypes = {
//   icon: PropTypes.object,
//   title: PropTypes.string,
//   id: PropTypes.string,
//   style: PropTypes.object,
// }

// Back.defaultProps = {
//   icon: <ArrowLeftOutlined />,
//   title: 'button.back',
//   style: { backgroundColor: '#eb9234', color: '#ffffff' },
//   id: 'btn-back',
// }

export default injectIntl(Back)
