import React from 'react'
import { Button } from 'antd'
// import PropTypes from 'prop-types'
import { injectIntl } from 'react-intl'
import { ExportOutlined } from '@ant-design/icons'

function Export({
  type = 'default',
  icon = <ExportOutlined />,
  title = 'button.export',
  onClick = () => {},
  id = 'btn-export',
  intl,
  loading = false,
  ...props
}) {
  const newTitle = title === 'button.export' ? intl.formatMessage({ id: title }) : title
  return (
    <Button
      icon={icon}
      type={type}
      onClick={onClick}
      id={id}
      disabled={loading}
      loading={loading}
      {...props}
    >
      {newTitle}
    </Button>
  )
}

// Export.propTypes = {
//   type: PropTypes.string,
//   icon: PropTypes.object,
//   title: PropTypes.string,
//   onClick: PropTypes.func,
//   id: PropTypes.string,
// }

// Export.defaultProps = {
//   type: 'default',
//   icon: <ExportOutlined />,
//   title: 'button.export',
//   onClick: () => {},
//   id: 'btn-export',
// }

export default injectIntl(Export)
