import React from 'react'
import { Button } from 'antd'
// import PropTypes from 'prop-types'
import { injectIntl } from 'react-intl'
import { SaveOutlined } from '@ant-design/icons'

function Save({
  type = 'primary',
  icon = <SaveOutlined />,
  title = 'button.save',
  id = 'btn-save',
  loading = false,
  intl,
  ...props
}) {
  const newTitle = title === 'button.save' ? intl.formatMessage({ id: title }) : title
  return (
    <Button type={type} icon={icon} id={id} disabled={loading} loading={loading} {...props}>
      {newTitle}
    </Button>
  )
}

// Save.propTypes = {
//   type: PropTypes.string,
//   icon: PropTypes.object,
//   title: PropTypes.string,
//   id: PropTypes.string,
//   onClick: PropTypes.func,
// }

// Save.defaultProps = {
//   type: 'primary',
//   icon: <SaveOutlined />,
//   title: 'button.save',
//   onClick: () => {},
//   id: 'btn-save',
// }

export default injectIntl(Save)
