import React from 'react'
import currencyFormatter from 'currency-formatter'
import { useSelector } from 'react-redux'
import { Input, InputNumber } from 'antd'
import { NumericFormat } from 'react-number-format'
import dayjs from 'dayjs'
import localStore from 'store'
import _ from 'lodash'

import { optionsSelector } from 'redux/selectors'
import { store } from 'index'

export const currency = (
  val,
  unformat = false,
  symbolNon = false,
  ifNull = false,
  precision,
  symbolDefault,
  skipPrefix,
) => {
  const { options } = store.getState().options
  let symbol = ''
  // tidak terpakai, karena sudah menggunakan alurbisnis
  if (symbolNon) {
    symbol = ''
  }

  if (options.show_property_currency_symbol === 1) {
    if (symbolDefault) {
      symbol = symbolDefault
    } else {
      symbol = options.property_currency_symbol ? `${options.property_currency_symbol} ` : 'Rp '
    }
  }

  if (skipPrefix) {
    symbol = ''
  }

  if (precision === undefined) {
    if (options.property_decimal !== null) {
      precision = options.property_decimal
    } else {
      precision = 0
    }
  }

  let format = { symbol, decimal: '.', precision: 0 }
  if (options.property_currency_format === '0.,') {
    format = { symbol, thousand: '.', precision: 0 }
  } else if (options.property_currency_format === '2-.') {
    format = { symbol, thousand: '', decimal: '.', precision }
  } else if (options.property_currency_format === '2-,') {
    format = { symbol, thousand: '', decimal: ',', precision }
  } else if (options.property_currency_format === '2.,') {
    format = { symbol, thousand: '.', decimal: ',', precision }
  } else if (options.property_currency_format === '2,.') {
    format = { symbol, thousand: ',', decimal: '.', precision }
  } else if (options.property_currency_format === '2 .') {
    format = { symbol, thousand: ' ', decimal: '.', precision }
  } else if (options.property_currency_format === '2 ,') {
    format = { symbol, thousand: ' ', decimal: ',', precision }
  }
  let result
  if (unformat) {
    val = `${val || ''}`.split(format.thousand || '').join('')
    result = currencyFormatter.unformat(val || 0, format)
  } else {
    result = currencyFormatter.format(val || 0, format)
  }

  if (ifNull && result === '0') {
    return '-'
  }
  if (unformat === 'format') {
    return format
  }
  return result
}

export const InputCurrency = React.forwardRef(
  ({ onBlur, onPressEnter, onFocus, onChange, showPrefix, className, ...props }, ref) => {
    const options = useSelector(optionsSelector)
    let format = currency(0, 'format', !showPrefix, false, 0, '', true)

    const onFocusHandler = React.useCallback(
      (e) => {
        e.target.select()
        if (onFocus) {
          onFocus(e)
        }
      },
      [onFocus],
    )

    const onKeyDownHandler = React.useCallback(
      (e) => {
        if (e.keyCode === 13) {
          if (onPressEnter) {
            onPressEnter(e.target.value, currency(e.target.value, true))
          }
        }
      },
      [onPressEnter],
    )

    const onBlurHandler = React.useCallback(
      (e) => {
        if (onBlur) {
          onBlur(e.target.value, currency(e.target.value, true))
        }
      },
      [onBlur],
    )

    format = {
      defaultValue: 0,
      decimalSeparator: format.decimal,
      thousandSeparator: format.thousand,
      prefix: format.symbol,
      className: `input-app-currency ${className}`,
      style: { paddingTop: 0, paddingBottom: 0, height: 32, textAlign: 'right' },
      onKeyDown: onKeyDownHandler,
      onBlur: onBlurHandler,
      decimalScale: options?.property_currency === 'IDR' ? 4 : 6,
      ...props,
      onChange: (e) => onChange(e.target.value, currency(e.target.value, true)),
      // isNumericString: true,
      onFocus: onFocusHandler,
    }

    return <NumericFormat customInput={Input} getInputRef={ref} {...format} />
  },
)

export const InputDiscount = React.forwardRef((props, ref) => {
  return (
    <InputNumber
      ref={ref}
      min={0}
      max={100}
      formatter={(value) => `${value}%`}
      parser={(value) => value.replace('%', '')}
      {...props}
    />
  )
})

// [
//   { "value": "2-.", "name": "1000.00" },
//   { "value": "2-,", "name": "1000,00" },
//   { "value": "2.,", "name": "1.000,00" },
//   { "value": "2,.", "name": "1,000.00" },
//   { "value": "2 .", "name": "1 000.00" },
//   { "value": "2 ,", "name": "1 000,00" },
//   { "value": "0.,", "name": "1.000" }
// ]

export const formatDate = (date = new Date(), format) => {
  const { options } = store.getState().options

  return dayjs(date).format(format || options.property_date_format)
}

/**
 *
 * @param date
 * @returns {string|null}
 */
export const toDbFormat = (date) =>
  dayjs(date || dayjs()).isValid() ? dayjs(date).format('YYYY-MM-DD') : null

export const formatTime = (date = new Date()) => {
  const { options } = store.getState().options

  return dayjs(date).format(options.property_time_format)
}

export const stringDateToMoment = (date) => {
  const castToMoment = dayjs(date)
  return castToMoment.isValid() ? castToMoment : dayjs()
}

export const formatDateText = () =>
  (store.getState().options &&
    store.getState().options.options &&
    store.getState().options.options.property_date_format) ||
  'YYYY-MM-DD'

// export const formatTimeText = () =>
//   (store.getState().options &&
//     store.getState().options.options &&
//     store.getState().options.options.property_time_format) ||
//   'HH:mm'

export const isObject = (value) => {
  return value && typeof value === 'object' && value.constructor === Object
}

export const qtyToCurrency = (props) => {
  let value = props
  const options = {}
  if (_.isObject(props)) {
    // eslint-disable-next-line prefer-destructuring
    value = props.value
    options.precision = props.precision
  }

  // angka di belakang koma dibatasi max 10 karna di js 0.1 + 0.2 === 0.30000000000000004
  // more info: https://javascript.info/number#imprecise-calculations
  const qty = parseFloat(parseFloat(value).toFixed(10))
  const result = qty - Math.floor(qty) !== 0
  if (result) {
    const count = qty.toString().split('.')[1]
    let precision = 2
    if (count) {
      precision = count.length
    }
    return currency(qty, false, true, false, options.precision || precision, '', true)
  }

  return currency(qty, false, true, false, 0, '', true)
}

export const qtyToCurrencyAutoComma = ({ value, maxPrecision }) => {
  const newValue = parseFloat(parseFloat(value).toFixed(10))
  const precision = `${newValue}`.includes('.') ? `${newValue}`.split('.')[1].length : 0

  return currency(
    newValue,
    false,
    true,
    false,
    typeof maxPrecision === 'number'
      ? precision > maxPrecision
        ? maxPrecision
        : precision
      : precision,
    '',
    true,
  )
}

/**
 *
 * @param value {string}
 * @param lowercaseAllFirst {boolean}
 * @returns {string}
 */
export const capitalizeFirstLetter = ({ value, lowercaseAllFirst }) => {
  let newValue = value

  if (lowercaseAllFirst) newValue = newValue.toLowerCase()

  return newValue.charAt(0).toUpperCase() + newValue.slice(1)
}

const staticTranslation = {
  'id-ID': {
    'email.email_confirmation': 'Konfirmasi Email',
    'email.email_template': 'Template Email',
    settings: 'Pengaturan',
    add: 'Tambah',
    edit: 'Ubah',
    detail: 'Detil',
    purchase_invoice: 'Tagihan Pembelian',
    purchase_order: 'Pesanan Pembelian',
    quote: 'Penawaran',
    invoice: 'Tagihan',
    tax: 'Pajak',
    transactions: 'Transaksi',
    bankstatements: 'Bank Statement',
    reconcile: 'Rekonsiliasi',
    'report.reports': 'Laporan',
    'report.financial': 'Finansial',
    'report.balance_sheet': 'Neraca',
    'report.cash_summary': 'Ringkasan Uang Tunai',
    'report.executive_summary': 'Ringkasan Eksekutif',
    'report.sales_tax_report': 'Laporan Pajak Penjualan',
    'report.accounting': 'Akuntansi',
    'report.aged_receivables': 'Umur Piutang',
    'report.customer_invoice_report': 'Laporan Tagihan Pelanggan',
    'report.aged_payables': 'Umur Hutang',
    'report.supplier_invoice_report': 'Laporan Tagihan Vendor',
    'report.purchases_per_supplier': 'Pembelian per Vendor',
    'report.cash_flow': 'Arus Kas',
    'report.net_cash_flows_from_': 'Arus kas bersih dari ',
    'report.direct_method': 'Metode Langsung',
    'report.indirect_method': 'Metode Tak Langsung',
    'report.please_select': 'Silahkan Pilih',
    'report.cash_and_cash_equivalent': 'Kas dan Setara Kas',
    'report.cash_and_cash_equivalent_at_beginning_period': 'Kas dan setara kas di awal periode',
    'report.cash_and_cash_equivalent_at_end_period': 'Kas dan setara kas di akhir periode',
    'report.net_change_in_cash_for_period': 'Perubahan kas untuk periode',
    'report.net_cash_flows': 'Arus kas bersih',
    'report.equity_movement': 'Pergerakan Ekuitas',
    'report.beginning': 'Awal',
    'report.ending': 'Akhir',
    'report.net_movement': 'Pergerakan Bersih',
    'report.account_transactions': 'Transaksi Akun',
    'approvals.waiting_for_approval': 'Menunggu Persetujuan',
    'approvals.rejected': 'Ditolak',
  },
  'en-US': {
    'email.email_confirmation': 'Email Confirmation',
    'email.email_template': 'Email Template',
    settings: 'Settings',
    add: 'Add',
    edit: 'Edit',
    detail: 'Detail',
    purchase_invoice: 'Purchase Invoice',
    purchase_order: 'Purchase Order',
    quote: 'Quote',
    invoice: 'Invoice',
    tax: 'Tax',
    transactions: 'Transactions',
    bankstatements: 'Bank Statements',
    reconcile: 'Reconcile',
    'report.reports': 'Reports',
    'report.financial': 'Financial',
    'report.balance_sheet': 'Balance Sheet',
    'report.cash_summary': 'Cash Summary',
    'report.executive_summary': 'Executive Summary',
    'report.sales_tax_report': 'Sales Tax Report',
    'report.accounting': 'Accounting',
    'report.aged_receivables': 'Aged Receivables',
    'report.customer_invoice_report': 'Customer Invoice Report',
    'report.aged_payables': 'Aged Payables',
    'report.supplier_invoice_report': 'Supplier Invoice Report',
    'report.purchases_per_supplier': 'Purchases per Supplier',
    'report.cash_flow': 'Cash Flow',
    'report.net_cash_flows_from_': 'Net cash flows from ',
    'report.direct_method': 'Direct Method',
    'report.indirect_method': 'Indirect Method',
    'report.please_select': 'Please select',
    'report.cash_and_cash_equivalent': 'Cash and Cash Equivalent',
    'report.cash_and_cash_equivalent_at_beginning_period':
      'Cash and cash equivalent at beginning of period',
    'report.cash_and_cash_equivalent_at_end_period': 'Cash and cash equivalent at end of period',
    'report.net_change_in_cash_for_period': 'Net change in cash for period',
    'report.net_cash_flows': 'Net cash flows',
    'report.equity_movement': 'Movement in Equity',
    'report.beginning': 'Beginning',
    'report.ending': 'Ending',
    'report.net_movement': 'Net Movement',
    'report.account_transactions': 'Account Transaction',
    'approvals.waiting_for_approval': 'Waiting for Approval',
    'approvals.rejected': 'Rejected',
  },
}

export const appMessage = (id) => {
  const locale = localStore.get('app.settings.locale')
  if (staticTranslation[locale]) {
    return staticTranslation[locale][id] ? staticTranslation[locale][id] : id
  }

  return id
}
