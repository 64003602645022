import React from 'react'
import { Button } from 'antd'
import { injectIntl } from 'react-intl'
import { RollbackOutlined } from '@ant-design/icons'

function Reset({
  type = 'primary',
  icon = <RollbackOutlined />,
  title = 'button.reset',
  onClick = () => {},
  id = 'btn-print',
  intl,
  ...props
}) {
  const newTitle = title === 'button.reset' ? intl.formatMessage({ id: title }) : title

  return (
    <Button type={type} icon={icon} onClick={onClick} id={id} {...props}>
      {newTitle}
    </Button>
  )
}

export default injectIntl(Reset)
