import React from 'react'
import { Button } from 'antd'
// import PropTypes from 'prop-types'
import { injectIntl } from 'react-intl'
import { DeleteOutlined } from '@ant-design/icons'

function Delete({
  type = 'primary',
  icon = <DeleteOutlined />,
  title = 'button.delete',
  onClick = () => {},
  id = 'btn-delete',
  intl,
  ...props
}) {
  const newTitle = title === 'button.delete' ? intl.formatMessage({ id: title }) : title
  return (
    <Button icon={icon} type={type} onClick={onClick} id={id} {...props} danger>
      {newTitle}
    </Button>
  )
}

// Delete.propTypes = {
//   type: PropTypes.string,
//   icon: PropTypes.object,
//   title: PropTypes.string,
//   id: PropTypes.string,
//   onClick: PropTypes.func,
// }

// Delete.defaultProps = {
//   type: 'primary',
//   icon: <DeleteOutlined />,
//   title: 'button.delete',
//   onClick: () => {},
//   id: 'btn-delete',
// }

export default injectIntl(Delete)
