import React from 'react'
import { Button } from 'antd'
// import PropTypes from 'prop-types'
import { injectIntl } from 'react-intl'
import { PrinterOutlined } from '@ant-design/icons'

function Print({
  type = 'default',
  icon = <PrinterOutlined />,
  title = 'button.print',
  onClick = () => {},
  id = 'btn-print',
  intl,
  ...props
}) {
  const newTitle = title === 'button.print' ? intl.formatMessage({ id: title }) : title

  return (
    <Button type={type} icon={icon} onClick={onClick} id={id} {...props}>
      {newTitle}
    </Button>
  )
}

// Print.propTypes = {
//   type: PropTypes.string,
//   icon: PropTypes.object,
//   title: PropTypes.string,
//   id: PropTypes.string,
//   onClick: PropTypes.func,
// }

export default injectIntl(Print)
