import React from 'react'
import { Button } from 'antd'
// import PropTypes from 'prop-types'
import { injectIntl } from 'react-intl'
import { EditOutlined } from '@ant-design/icons'

function Edit(
  type = 'primary',
  icon = <EditOutlined />,
  title = 'button.edit',
  onClick = () => {},
  id = 'btn-edit',
  intl,
  loading = false,
  ...props
) {
  const newTitle = title === 'button.edit' ? intl.formatMessage({ id: title }) : title
  return (
    <Button
      icon={icon}
      type={type}
      onClick={onClick}
      id={id}
      disabled={loading}
      loading={loading}
      {...props}
    >
      {newTitle}
    </Button>
  )
}

// Edit.propTypes = {
//   type: PropTypes.string,
//   icon: PropTypes.object,
//   title: PropTypes.string,
//   id: PropTypes.string,
//   onClick: PropTypes.func,
// }

// Edit.defaultProps = {
//   type: 'primary',
//   icon: <EditOutlined />,
//   title: 'button.edit',
//   onClick: () => {},
//   id: 'btn-edit',
// }

export default injectIntl(Edit)
