import React from 'react'
import { Col, Row, Tag } from 'antd'
import PropTypes from 'prop-types'

import { transType } from 'utils/static'
import { checkContrast, hexToRGB } from 'utils/helper'
import LinkTransType from '../LinkTransType'
import styles from './styles.module.scss'

const Tags = ({ asLink, data, tagProps }) => {
  const { className, style = {} } = tagProps

  return (
    <Row>
      {data?.map?.((tag) => {
        if (!tag) return null

        const backgroundColor = hexToRGB(tag.color ?? '#fafafa')
        const color =
          checkContrast(backgroundColor, 'rgb(255, 255, 255)') >= 4.5
            ? 'rgb(255, 255, 255)'
            : 'rgb(0, 0, 0)'

        return (
          <Col key={tag.id} className="mt-2">
            <Tag
              {...tagProps}
              style={{ '--color': color, '--background-color': backgroundColor, ...style }}
              className={`${className ?? ''} ${styles.tag}`}
            >
              {asLink ? (
                <LinkTransType
                  title={tag.name}
                  transTypeId={transType.TAG}
                  data={{ id: tag.id }}
                  style={{ '--color': color }}
                  className={styles.tagLink}
                />
              ) : (
                tag.name
              )}
            </Tag>
          </Col>
        )
      })}
    </Row>
  )
}

Tags.propTypes = {
  data: PropTypes.array.isRequired,
  goTo: PropTypes.func,
  tagProps: PropTypes.object,
  asLink: PropTypes.bool,
}

Tags.defaultProps = {
  goTo: () => {},
  tagProps: {
    style: {marginTop: 1, fontSize: 12, padding: '1px 7px'}
  },
  asLink: true,
}

export default Tags
